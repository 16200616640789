import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';

/* eslint-disable */
@Injectable()
export class ApiConfig {
  static readonly backendUrl = environment.gkUrl + '/gk_ws/';

  static readonly defaultOwners = ['M', 'F'];
  static readonly gkWms = environment.wmsUrl + '/cgi-bin/intern_gardskart';
  static readonly imageUrl = 'https://gardskart.nibio.no';
  static readonly kommuneListe = environment.kommUrl + '/municipality_cache_ws/kommuneListe';
  static readonly LIB_ERRORCOUNT_LIMIT = 10;
  // we have to proxy (proxy.conf.json) to allow CORS
  static readonly proxiedGkUrl = '/backend';
  // static readonly proxiedAiUrl = environment.gkUrl + '/gardskart_ki/';
  static readonly proxiedAiUrl = ApiConfig.proxiedGkUrl + '/gardskart_ki/';
  static readonly proxiedBackendUrl = ApiConfig.proxiedGkUrl + '/gk_ws/';

  static readonly matrikkel_property_request = ApiConfig.proxiedBackendUrl + 'search_matrikkel_property_request';
  static readonly matrikkel_property_status = ApiConfig.proxiedBackendUrl + 'search_matrikkel_property_status';
  // The value of the max_age parameter in backend requests
  static readonly max_age = 60;

  static readonly orthoProjectsUrl = '/nib2/rest/projectMetadata.ashx';
  static readonly printPdfUrl = environment.gkUrl + '/printpdf/';

  static readonly printUrlBackend = ApiConfig.proxiedBackendUrl + 'print';
  static readonly property_request = ApiConfig.proxiedBackendUrl + 'search_property_request/';

  static readonly property_status = ApiConfig.proxiedBackendUrl + 'search_property_status/';
  static readonly specialOwners = ['Ff', 'Fg', 'Sx', 'S', 'T'];

  static readonly subproperties_list = ApiConfig.proxiedBackendUrl + 'list_subproperties/';

  static readonly system_state = ApiConfig.proxiedBackendUrl + 'check_system_state';
  static readonly tokenUrl = ApiConfig.proxiedGkUrl + '/token/';

  static readonly ticket_fkb2 = ApiConfig.tokenUrl + 'ticket_fkb2.jsp';

  static readonly ticket_nib = ApiConfig.tokenUrl + 'ticket_nib_json.jsp';
  static readonly ticket_nib_project = ApiConfig.tokenUrl + 'ticket_nib_project_json.jsp';

  static readonly ticket_topo3 = ApiConfig.tokenUrl + 'ticket_topo3.jsp';
  static readonly token_nib = ApiConfig.tokenUrl + 'token_json.jsp';
}
