import { Component, HostBinding, inject, input } from '@angular/core';
import { Store } from '@ngrx/store';
import { CANCEL_GK_REQUESTS } from '@reducers/cancelGkRequests.reducer';
import { StoreStates } from '@reducers/store-states';
import { EndPointService } from '@services/endpoint.service';

@Component({
  selector: 'gk-spinner',
  standalone: true,
  styleUrls: ['./spinner.component.scss'],
  templateUrl: './spinner.component.html',
})
export class SpinnerComponent {
  private eps = inject(EndPointService);
  private store = inject<Store<StoreStates>>(Store);

  @HostBinding('class.inline')
  inline = input(false);
  color = input<'' | 'black' | 'green'>('');
  size = input<'' | 's'>(''); // Add xs, s, m, l in .scss to use here

  cancelRouting() {
    this.eps.cancelRequests();
    // cancel gk requests:
    // this.store.dispatch({ type: UPDATE_MAP_LAYER, payload: 'none' });
    // better when we do not want 'none' after legg til:
    this.store.dispatch({ type: CANCEL_GK_REQUESTS });
  }
}
